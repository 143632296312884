var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-input"
  }, [_c('div', {
    staticClass: "board-input__contents"
  }, [_c('div', {
    staticClass: "py-12"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "board-input__row-header",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', [_vm._v("Title")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-12"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "board-input__row-header",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', [_vm._v("Title")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "py-12"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "grey-6"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v("Confirm")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }